import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './utils/theme'
import ProtectedRoute from './ProtectedRoute'
import MainRouter from './MainRouter'
import './index.css'
import { AUTH0_API_IDENTIFIER, AUTH0_CLIENT_ID, AUTH0_REDIRECT_URI, AUTH0_SCOPE, AUTH0_TENET_DOMAIN } from './consts'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_TENET_DOMAIN}
      audience={AUTH0_API_IDENTIFIER}
      redirectUri={AUTH0_REDIRECT_URI}
      clientId={AUTH0_CLIENT_ID}
      scope={AUTH0_SCOPE}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ProtectedRoute>
          <MainRouter />
        </ProtectedRoute>
        <Toaster position='top-center' toastOptions={{ duration: 4000 }} />
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
