import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useAuth0 } from '@auth0/auth0-react'
import { makeStyles } from '@mui/styles'
import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import { ArticleOutlined, LockOutlined, PersonOutlineRounded } from '@mui/icons-material'
import { MANAGEMENT_SERVICE_URL } from '../consts'

const useStyles = makeStyles(
  () => ({
    wrapper: {
      width: '95%',
      margin: '1rem auto',
      padding: '1.5rem',
      backgroundColor: 'white',
      borderRadius: 10,
      border: '1px solid #e3e4e6',
      boxShadow: '1px 1px 3px 0px rgb(157 157 157 / 85%)',
    },
    form: {
      width: '100%',
      margin: '1rem 0 0 0',
      display: 'flex',
      flexDirection: 'column',
      '& .MuiFormControl-root': {
        margin: '0.3rem 0',
      },
      '& .MuiButton-root': {
        margin: '0.3rem 0',
        backgroundColor: 'black',
        color: 'white',
      },
    },
  }),
  { name: 'Account' }
)

const updateProfile = async (
  token: string,
  payload: {
    email?: string
    password?: string
    firstName?: string
    lastName?: string
  }
): Promise<void> => {
  const res = await fetch(`${MANAGEMENT_SERVICE_URL}/user`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })

  if (res.ok) {
    toast.success('Successfully updated account information')
  } else {
    toast.error('An error occurred while try to update account information')
  }
}

const Wrapper = ({
  className,
  Icon,
  title,
  subTitle,
  children,
}: {
  className: string
  Icon?: React.FunctionComponent
  title?: string
  subTitle?: string
  children: JSX.Element
}): JSX.Element => {
  return (
    <div className={className}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {Icon ? <Icon /> : null}
        {title && (
          <div style={{ marginLeft: 10 }}>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold', fontSize: 18 }}>
              {title}
            </Typography>
            <Typography variant='subtitle2'>{subTitle}</Typography>
          </div>
        )}
      </div>

      {children}
    </div>
  )
}

const Account = (): JSX.Element => {
  const { user, getAccessTokenSilently } = useAuth0()
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState(user?.given_name ?? '')
  const [lastName, setLastName] = useState(user?.family_name ?? '')
  const [email, setEmail] = useState(user?.email ?? '')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const savePersonalInformation = async (): Promise<void> => {
    setLoading(true)

    await updateProfile(await getAccessTokenSilently(), {
      firstName: firstName ? firstName : undefined,
      lastName: lastName ? lastName : undefined,
      email: email ? email : undefined,
    })

    setLoading(false)
  }

  const saveNewPassword = async (): Promise<void> => {
    setLoading(true)

    if (!newPassword !== !confirmPassword) {
      toast.error('Please enter password and confirmation!')

      return
    }

    if (newPassword !== confirmPassword) {
      toast.error('Passwords not match!')

      return
    }

    await updateProfile(await getAccessTokenSilently(), {
      password: newPassword,
    })

    setLoading(false)
  }

  return (
    <div>
      <Wrapper
        className={classes.wrapper}
        title='Your Profile'
        subTitle='Manage your personal information'
        Icon={() => <PersonOutlineRounded sx={{ fontSize: 50 }} />}
      >
        <div />
      </Wrapper>

      <Wrapper
        className={classes.wrapper}
        title='Your Information'
        subTitle='Change your information here'
        Icon={() => <ArticleOutlined sx={{ fontSize: 50 }} />}
      >
        <form className={classes.form}>
          <TextField label='First Name' placeholder='John' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          <TextField label='Last Name' placeholder='Doe' value={lastName} onChange={(e) => setLastName(e.target.value)} />
          <TextField label='Email' placeholder='john_doe@example.com' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
          {loading ? (
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <Button onClick={async () => await savePersonalInformation()}>Save</Button>
          )}
        </form>
      </Wrapper>

      <Wrapper
        className={classes.wrapper}
        title='Your Password'
        subTitle='Change your password here'
        Icon={() => <LockOutlined sx={{ fontSize: 50 }} />}
      >
        <form className={classes.form}>
          <TextField label='New Password' type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          <TextField label='Confirm Password' type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
          {loading ? (
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <Button onClick={async () => await saveNewPassword()}>Save</Button>
          )}
        </form>
      </Wrapper>
    </div>
  )
}

export default Account
