import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileViewer from 'react-file-viewer'
import { useAuth0 } from '@auth0/auth0-react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material'
import { Input } from '@everybyte-io/components'
import getQuery from '../functions/get-query'

const imageFileTypes = ['.png', '.jpg', '.jpeg']
const documentFileTypes = ['.pdf', '.xlsx', '.docx', '.csv']
const docTypes = [
  {
    key: 'monitoredCashReportTwoYears',
    label: 'דוחות כספיים מבוקרים שנתיים אחרונות',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'trialBalanceCurrentYear',
    label: 'מאזן בוחן שנה נוכחית',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'checkingReportThreeMonths',
    label: 'תנועות עו"ש 3 חודשים אחרונים מכלל הבנקים',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'balanceReport',
    label: 'דוח ריכוז יתרות מכלל הבנקים',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'creditReport',
    label: 'דוח נתוני אשראי של הבעלים',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'landRegistration',
    label: 'נסח טאבו / אישור זכויות עדכני',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'standardAppraisals19',
    label: 'שמאות תקן 19',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'priceOffer',
    label: 'הצעת מחיר',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'investmentReport',
    label: 'דוח פירוט תיק השקעות (כמות מניות, שווי מניות)',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'driversLicense',
    label: 'רישיון רכב (לטובת הערכת שווי)',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'collateralNationalId',
    label: 'צילום תעודת זהות של הערב',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'collateralCheckingAndBalanceRport',
    label: 'נתוני עו"ש + ריכוז נתונים של הערב',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'collateralCreditReport',
    label: 'דוח נתוני אשראי שחל הערב',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'collateralPaycheck',
    label: 'תלושי שכר של הערב',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
  {
    key: 'discountCheques',
    label: 'צילום שיקים מיועדים לניכיון',
    supportedTypes: [...imageFileTypes, ...documentFileTypes],
    allowMultiple: true,
  },
]

const mandatoryDocKeys = ['monitoredCashReportTwoYears', 'trialBalanceCurrentYear', 'checkingReportThreeMonths', 'balanceReport', 'creditReport']
const setsOfDocKeys = {
  solo: [...mandatoryDocKeys],
  realEstate: [...mandatoryDocKeys, 'landRegistration', 'standardAppraisals19'],
  mechanics: [...mandatoryDocKeys, 'priceOffer'],
  investments: [...mandatoryDocKeys, 'investmentReport'],
  vehicles: [...mandatoryDocKeys, 'driversLicense'],
  collateral: [...mandatoryDocKeys, 'collateralNationalId', 'collateralCheckingAndBalanceRport', 'collateralCreditReport', 'collateralPaycheck'],
  cheques: [...mandatoryDocKeys, 'discountCheques'],
}

const allowedQueryKeys = ['solo', 'realEstate', 'mechanics', 'investments', 'vehicles', 'collateral', 'cheques']

const useStyles = makeStyles(
  () => ({
    container: {
      width: '95%',
      margin: '1rem auto',
    },
    label: {
      margin: '0.5rem 0.2rem',
    },
    selectFile: {
      width: '100%',
      minHeight: '50px',
      padding: '1rem',
      backgroundColor: 'whitesmoke',
      borderRadius: '0.5rem',
      border: '1px solid lightgray',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '& input': {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
        opacity: 0,
      },
      '& svg': {
        fill: 'gray',
      },
      '& p': {
        textAlign: 'center',
        margin: '0.5rem auto',
      },
      '& img': {
        maxWidth: '300px',
        width: '100%',
        margin: '0.5rem auto',
        borderRadius: '1rem',
      },
      '& .pg-viewer-wrapper': {
        maxWidth: '300px',
        // width: '100%',
        height: '100%',
        margin: '0.5rem auto',
        borderRadius: '1rem',
      },
      '& canvas': {
        width: '100%',
        // height: '100%',
      },
    },
  }),
  { name: 'Upload' }
)

interface FileData {
  name: string
  ext: string
  type: string
  size: string
  base64: string
}

// eslint-disable-next-line no-unused-vars
type ReadFileFunc = (_event: ChangeEvent<HTMLInputElement>, _key: string) => void

const Upload = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  const q = getQuery(location.search)
  const mode = (q.mode ?? 'solo') as keyof typeof setsOfDocKeys

  const { user } = useAuth0()
  const fullName =
    user?.given_name && user?.family_name
      ? `${user?.given_name} ${user?.family_name}`
      : user?.given_name
        ? user?.given_name
        : user?.family_name
          ? user?.family_name
          : ''
  const [nationalId, setNationalId] = useState('')
  const [requestId, setRequestId] = useState('')

  const [files, setFiles] = useState<Record<string, FileData[]>>({})
  const [openDemo, setOpenDemo] = useState(false)

  useEffect(() => {
    if (Object.keys(files).length) {
      toast.success('File loaded')
    }
  }, [files])

  const readFiles: ReadFileFunc = (e, key) => {
    const allFiles = e.target.files

    if (allFiles?.length) {
      for (let i = 0; i < allFiles.length; i++) {
        const file = allFiles[i]
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = () => {
          const nameSplitted = file.name.split('.')
          const payload = {
            name: file.name,
            ext: nameSplitted[nameSplitted.length - 1],
            type: file.type,
            size: Math.round(file.size / 1000) + ' kB',
            base64: reader.result as string,
          }

          setFiles((prev) => {
            if (prev[key]) {
              return { ...prev, [key]: [...prev[key], payload] }
            }

            return { ...prev, [key]: [payload] }
          })
        }
      }
    } else {
      console.warn('No files selected')
    }
  }

  if (!allowedQueryKeys.includes(mode)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <br />
        <br />
        Developer notice!
        <br />
        Unsupported &ldquo;mode&rdquo; query, please use one of the following modes (case sensetive):
        <ul>
          {allowedQueryKeys.map((str) => (
            <li key={str}>{str}</li>
          ))}
        </ul>
      </div>
    )
  }

  const Demo = (): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          zIndex: '999999',
        }}
      >
        {openDemo ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {Object.keys(setsOfDocKeys).map((key) => (
              <Button
                key={`demo-${key}`}
                onClick={() => {
                  navigate(`${location.pathname}?mode=${key}`)
                  setOpenDemo(false)
                }}
                style={{
                  margin: '0.2rem 0',
                  backgroundColor: 'orange',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                {key}
              </Button>
            ))}
          </div>
        ) : null}

        <Button
          style={{
            backgroundColor: 'orange',
            color: 'black',
            fontWeight: 'bold',
          }}
          onClick={() => setOpenDemo((prev) => !prev)}
        >
          Demo
        </Button>
      </div>
    )
  }

  return (
    <div>
      <Demo />

      <div dir='rtl' style={{ width: '100%', margin: '2rem 0', display: 'flex', flexFlow: 'row wrap' }}>
        <div onClick={() => navigate('/account')} style={{ width: '45%', margin: '0.2rem auto' }}>
          <Input isRtl label='שם מלא' style={{ width: '100%', margin: '0' }} value={fullName} setValue={(v) => console.log(v)} readOnly />
        </div>
        <Input isRtl label='תעודת זהות' style={{ width: '45%', margin: '0.2rem auto' }} value={nationalId} setValue={(v) => setNationalId(v)} />
        <div onClick={() => setOpenDemo((prev) => !prev)} style={{ width: '45%', margin: '0.2rem auto' }}>
          <Input isRtl label='סוג ההלוואה' style={{ width: '100%', margin: '0' }} value={mode} setValue={(v) => console.log(v)} readOnly />
        </div>
        <Input isRtl label='מספר הבקשה' style={{ width: '45%', margin: '0.2rem auto' }} value={requestId} setValue={(v) => setRequestId(v)} />
      </div>

      {docTypes.map(({ key, label, supportedTypes, allowMultiple }) =>
        setsOfDocKeys[mode].includes(key) ? (
          <div key={`fileSelector_${key}`} className={classes.container}>
            <h5 className={classes.label} dir='rtl'>
              {label}:
            </h5>

            <div className={classes.selectFile}>
              <input type='file' accept={supportedTypes.join(',')} multiple={allowMultiple} onChange={(e) => readFiles(e, key)} />
              <CloudUploadIcon />

              {files[key] && files[key].length
                ? files[key].map(({ ext, base64, name, size }, idx) => (
                  <div key={`selectedFile_${key}_${idx}`}>
                    <p>
                      <strong>File name:</strong> {name}
                      <br />
                      <strong>File Size:</strong> {size}
                    </p>

                    {imageFileTypes.includes(`.${ext}`) ? (
                      <img key={`selectedFile_preview_${key}_${idx}`} src={base64} alt='' />
                    ) : (
                      <FileViewer key={`selectedFile_preview_${key}_${idx}`} fileType={ext} filePath={base64} />
                    )}
                  </div>
                ))
                : null}
            </div>
          </div>
        ) : null
      )}
    </div>
  )
}

export default Upload
