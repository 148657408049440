import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loader from './components/Loader'

export default withAuthenticationRequired(({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => <>{children}</>, {
  onRedirecting: () => (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader />
    </div>
  ),
})
