const ENVIRONMENT = String(process.env.REACT_APP_ENVIRONMENT ?? 'development')

const AUTH0_TENET_DOMAIN = String(process.env.REACT_APP_AUTH0_TENET_DOMAIN ?? 'dev-5v734fof.us.auth0.com')
const AUTH0_API_IDENTIFIER = String(process.env.REACT_APP_AUTH0_API_IDENTIFIER ?? 'http://localhost:3000')
const AUTH0_CLIENT_ID = String(process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'w0PYnfYNq8sWDB7Wemkq2jerqSxX3iGQ')
const AUTH0_REDIRECT_URI = window.location.origin
const AUTH0_SCOPE = ['read:users', 'update:users'].join(' ')

const LOGO_URL = 'https://open-finance-media.s3.eu-central-1.amazonaws.com/logos/transparent-white-no-padding.png'
const MANAGEMENT_SERVICE_URL = String(process.env.REACT_APP_MANAGEMENT_SERVICE_URL ?? 'http://localhost:9000')

export { ENVIRONMENT, AUTH0_TENET_DOMAIN, AUTH0_API_IDENTIFIER, AUTH0_REDIRECT_URI, AUTH0_CLIENT_ID, AUTH0_SCOPE, LOGO_URL, MANAGEMENT_SERVICE_URL }
