import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { makeStyles } from '@mui/styles'
import { AppBar, IconButton, Avatar, Popover, List, ListSubheader, ListItemButton, Typography } from '@mui/material'
import { LOGO_URL } from '../consts'

const useStyles = makeStyles(
  () => ({
    header: {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    logo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiAvatar-root': {
        marginRight: '0.5rem',
        padding: '0.2rem',
      },
    },
  }),
  { name: 'Header' }
)

const Header = (): JSX.Element => {
  const classes = useStyles()
  const { logout, user } = useAuth0()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(null)
  const [popover, setPopover] = useState<boolean>(false)

  const openPopover: React.MouseEventHandler<HTMLButtonElement> = (e): void => {
    setPopover(true)
    setAnchorEl(e.currentTarget)
  }

  const closePopover = (): void => {
    setPopover(false)
    setAnchorEl(null)
  }

  const clickLogo = (): void => {
    closePopover()
    navigate('/')
  }

  const clickUpload = (): void => {
    closePopover()
    navigate('/upload')
  }

  const clickAccount = (): void => {
    closePopover()
    navigate('/account')
  }

  const clickLogout = (): void => {
    closePopover()
    logout()
  }

  return (
    <AppBar position='static'>
      <div className={classes.header}>
        <div className={classes.logo} onClick={clickLogo}>
          <Avatar src={LOGO_URL} alt='' />
          <Typography variant='h6' style={{ width: 'fit-content' }}>
            Open Finance
          </Typography>
        </div>

        <IconButton onClick={openPopover} sx={{ width: 'fit-content' }}>
          <Avatar src={user?.picture && user?.picture.indexOf('gravatar') === -1 ? user?.picture || '' : user?.name} alt={user?.name || ''} />
        </IconButton>

        <Popover
          anchorEl={anchorEl}
          open={popover}
          onClose={closePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List style={{ minWidth: '100px' }}>
            <ListSubheader style={{ textAlign: 'center' }}>Hello, {user?.name || ''}</ListSubheader>
            <ListItemButton onClick={clickUpload}>Upload Documents</ListItemButton>
            <ListItemButton onClick={clickAccount}>My Account</ListItemButton>
            <ListItemButton onClick={clickLogout}>Logout</ListItemButton>
          </List>
        </Popover>
      </div>
    </AppBar>
  )
}

export default Header
