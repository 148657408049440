import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Header from './components/Header'
import Upload from './pages/Upload'
import Account from './pages/Account'

const MainRouter = (): JSX.Element => {
  return (
    <Router>
      <div>
        <Header />
        <div style={{ margin: '0 auto', maxWidth: '555px' }}>
          <Routes>
            <Route path='/account' element={<Account />} />
            <Route path='/upload' element={<Upload />} />
            <Route path='*' element={<Navigate to='/upload' />} />
          </Routes>
        </div>
      </div>
    </Router>
  )
}

export default MainRouter
